/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: gray;
  text-align: center;
  font-weight: 500;
  height: 1.5em;
  opacity: 1;

  &:before {
    content: "";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: lightgray;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: gray;
    font-size: 18px;
    padding: 0 0.7em;
    line-height: 1.2em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    background-color: #fcfcfa;
  }
}

.alert-wrapper {
  .alert-title.sc-ion-alert-md {
    color: var(--ion-text-color, #000);
    font-size: 20px;
    font-weight: 700;
  }

  .alert-message {
    max-height: 266px;
    font-size: 16px;
    color: gray;
    font-weight: 600;
  }
}

.item-label-floating.item-has-placeholder.sc-ion-input-ios-h:not(
    .item-has-value
  ),
.item-label-floating.item-has-placeholder:not(.item-has-value)
  .sc-ion-input-ios-h {
  opacity: 1 !important;
  --placeholder-color: white !important;
}

.item-label-floating.item-has-placeholder.sc-ion-textarea-ios-h:not(
    .item-has-value
  ),
.item-label-floating.item-has-placeholder:not(.item-has-value)
  .sc-ion-textarea-ios-h {
  opacity: 1 !important;
  --placeholder-color: white !important;
}

.item-label-floating.item-has-placeholder.sc-ion-input-ios-h,
.item-label-floating.item-has-placeholder .sc-ion-input-ios-h {
  --placeholder-color: gray;
}

.item-has-focus.label-floating.sc-ion-label-ios-h,
.item-has-focus .label-floating.sc-ion-label-ios-h,
.item-has-placeholder.sc-ion-label-ios-h:not(.item-input).label-floating,
.item-has-placeholder:not(.item-input) .label-floating.sc-ion-label-ios-h,
.item-has-value.label-floating.sc-ion-label-ios-h,
.item-has-value .label-floating.sc-ion-label-ios-h {
  transform: translate3d(0, 10px, 0) scale(0.82);
  -webkit-transform: translate3d(0, 10px, 0) scale(0.82);
}

.cust-modal-50 .modal-wrapper {
  height: 70%;
  position: absolute;
  bottom: 0;
}

.cust-modal-mentions {
  height: 80% !important;

  position: absolute;
  top: 0;
}

.cust-modal-30 .modal-wrapper {
  min-height: 50%;
  height: auto !important;
  position: absolute;
  bottom: 0;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.no-margin-top {
  margin-top: 0px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px;
  left: 0;
  width: 100%;
  position: absolute;
  margin-top: 15px;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-bullet {
  height: 5px;
  width: 5px;
}

ion-buttons.quantity {
  border: solid black 1px;
  border-radius: 50px;
  width: 150px;
  display: inline-block;
  text-align: center;
}

.border-left {
  border-left: solid black 1px;
}

.border-right {
  border-right: solid black 1px;
}

.text-hidden {
  display: -webkit-box;
  height: 16px;
  margin-top: -10px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom:0rem ;
}

.icon {
  background: red;
  height: 14px;
  width: 14px;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: -2px;
  color: white;
  font-size: 8.5px;
  font-weight: 700;
  padding-top: 3.5px;
}

.md-drppicker {
  width: 100% !important;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.ngx-otp-input {
  border: solid lightgray 1px !important;
  border-radius: 5px !important;
  height: 50px !important;
  width: 70% !important;
}

@media only screen and (min-width: 200px) and (max-width: 400px) {
  .ngx-otp-input {
    border: solid lightgray 1px !important;
    border-radius: 5px !important;
    height: 40px !important;
    width: 100% !important;
  }
}

ion-accordion.accordion-animated > [slot="header"] .ion-accordion-toggle-icon {
  display: none;
}

app-product-add {
  .list-header-inner {
    padding-bottom: 10px !important;
  }
}

@media only screen and (min-width: 250px) and (max-width: 300px) {
  .bottonTabs {
    width: 100% !important;
  }
}

@media only screen and (min-width: 301px) and (max-width: 420px) {
  .bottonTabs {
    width: 100% !important;
  }
}

@media only screen and (min-width: 421px) and (max-width: 1280px) {
  .bottonTabs {
    width: 100% !important;
  }
}
/* Global CSS */
.green-text {
  color: green;
}

.back-color{
  background-color: #385F69 !important;
  
}
:root {
	--ion-color-secondary-contrast-rgb: #7D2C42;
	--ion-color-secondary-shade: #7D2C42;
	--ion-color-secondary-tint:#924b5e;
	--ion-color-secondary: #7D2C42;
	--ion-color-secondary-rgb:#7D2C42;}
